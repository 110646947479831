import { useState } from "react";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import SplitLayout from "../../components/splitLayout";
import Axios from "../../AxiosConfig";
const AIResponse = () => {
  const [isFeedbackProcessing, setIsFeedbackProcessing] = useState(false);
  const [modelFeedback, setModelFeedback] = useState("");

  const ProcessFeedback = (e) => {
    const feedback = e.target.feedback.value;
    setIsFeedbackProcessing(true);
    const user = JSON.parse(localStorage.getItem("user")).email;
    const API_URL = process.env.REACT_APP_API_URL;
    Axios.post(`${API_URL}/feedback/ai`, { feedback, user })
      .then((response) => {
        setModelFeedback(response.data.feedback);
      })
      .catch((error) => {
        setModelFeedback("An error occurred");
      })
      .finally(() => {
        setIsFeedbackProcessing(false);
      });
  };

  return (
    <>
      <SplitLayout
        LeftSection={() => <LeftSection ProcessFeedback={ProcessFeedback} />}
        RightSection={() => (
          <RightSection
            Loading={isFeedbackProcessing}
            Feedback={modelFeedback}
          />
        )}
      />
    </>
  );
};
export default AIResponse;
