import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ViewModal from "./viewModal";
import NewModal from "./newModal";
import Axios from "../../AxiosConfig";
const TemplateResponse = () => {
  const [allTemplates, setAllTemplates] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [query, setQuery] = useState("");

  const [openViewModal, setOpenViewModal] = useState(false);
  const [openNewModal, setOpenNewModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [pending, setPending] = useState(true);
  const getTemplates = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    Axios.post(`${API_URL}/templates/get`, {}).then((res) => {
      setTemplates(res.data.templates);
      setAllTemplates(res.data.templates);
      setPending(false);
    });
  };
  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Type",
      cell: (row) => (
        <span className="px-2 py-1 text-xs font-medium text-gray-600">
          {row.type === "default" ? "Default" : "User Created"}
        </span>
      ),
    },
    {
      name: "Action",
      cell: (row, index) => (
        <div>
          <button
            onClick={() => {
              setSelectedTemplate(row);
              setOpenViewModal(true);
            }}
            className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Use Template
          </button>
          {row.type === "user" && (
            <button
              onClick={() => {
                // Show a confirmation dialog before deleting the template
                if (
                  window.confirm(
                    "Are you sure you want to delete this template?"
                  )
                ) {
                  setPending(true);
                  const API_URL = process.env.REACT_APP_API_URL;
                  Axios.post(`${API_URL}/templates/delete`, {
                    id: row._id,
                  }).then((res) => {
                    getTemplates();
                  });
                }
              }}
              className="ml-3 rounded bg-white px-2 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
            >
              Delete
            </button>
          )}
        </div>
      ),
    },
  ];
  const saveTemplate = (title, value) => {
    setPending(true);
    const API_URL = process.env.REACT_APP_API_URL;
    const user = JSON.parse(localStorage.getItem("user")).email;
    Axios.post(`${API_URL}/templates/save`, {
      title: title,
      value: value,
      user,
      type: "user",
    }).then((res) => {
      getTemplates();
    });
  };
  const updateTemplate = (id, value) => {
    const API_URL = process.env.REACT_APP_API_URL;
    Axios.post(`${API_URL}/templates/update`, {
      id,
      value,
    }).then((res) => {
      setOpenViewModal(false);
      getTemplates();
    });
  };
  useEffect(() => {
    getTemplates();
  }, []);
  return (
    <div className="rounded-lg bg-white shadow">
      <div className="p-6">
        <div className="grid grid-cols-1 sm:grid-cols-5 gap-4">
          <div className="sm:col-span-4">
            <div>
              <input
                type="search"
                placeholder="Search In Template Title"
                onChange={(e) => {
                  setQuery(e.target.value);
                  setTemplates(
                    allTemplates.filter((template) =>
                      template.title.toLowerCase().includes(e.target.value)
                    )
                  );
                }}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <button
            onClick={() => setOpenNewModal(true)}
            className="rounded-md bg-gray-800 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          >
            + New Template
          </button>
        </div>
        <DataTable
          columns={columns}
          data={templates}
          progressPending={pending}
          pagination
        />
      </div>
      <ViewModal
        key={
          selectedTemplate.title +
          selectedTemplate.value +
          selectedTemplate.type
        }
        open={openViewModal}
        setOpen={setOpenViewModal}
        template={selectedTemplate}
        updateTemplate={updateTemplate}
      />
      <NewModal
        open={openNewModal}
        setOpen={setOpenNewModal}
        saveTemplate={saveTemplate}
      />
    </div>
  );
};
export default TemplateResponse;
