import { useLocalStorage } from "../../hooks/useLocalStorage";
// Read json file
import feedbacks from "./feedbacks.json";
const LeftSection = ({ ProcessFeedback }) => {
  const [feedback, setFeedback] = useLocalStorage("feedback", "");
  return (
    <div>
      <p className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl border-b pb-2 mb-2">
        Generate AI Response
      </p>
      <div>
        <label
          htmlFor="defaultTemplates"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Demo Examples:
        </label>
        <select
          id="defaultTemplates"
          name="defaultTemplates"
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-teal-600 sm:text-sm sm:leading-6"
          defaultValue=""
          onChange={(e) => setFeedback(e.target.value)}
        >
          <option value={""}>Select A Template</option>
          {feedbacks.map((feedback, index) => (
            <option value={feedback.value} key={index}>
              {feedback.title}
            </option>
          ))}
        </select>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          ProcessFeedback(e);
        }}
      >
        <div className="mt-2">
          <textarea
            rows={4}
            name="feedback"
            id="feedback"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
            defaultValue={feedback ? feedback : ""}
            onChange={(e) => setFeedback(e.target.value)}
            value={feedback}
            placeholder="Enter your feedback here..."
            required
          />
        </div>
        <div className="mt-4 text-right">
          <button
            type="submit"
            className="rounded-md bg-gray-800 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          >
            Process Feedback
          </button>
        </div>
      </form>
    </div>
  );
};
export default LeftSection;
