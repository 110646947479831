import { useNavigate } from "react-router-dom";
const Applicants = () => {
  const navigate = useNavigate();
  return (
    <div>
      <main className="grid min-h-full place-items-center  px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Coming Soon
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            We're working hard to bring you the best experience. Stay tuned for
            updates.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            {/* <button
              onClick={() => navigate("/")}
              className="rounded-md bg-gray-800 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            >
              Go Back Home
            </button> */}
          </div>
        </div>
      </main>
    </div>
  );
};
export default Applicants;
