import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useAuth } from "../hooks/useAuth";
import { useLocation } from "react-router-dom";
import {
  Bars3Icon,
  UsersIcon,
  XMarkIcon,
  SparklesIcon,
  DocumentTextIcon,
  ArrowRightEndOnRectangleIcon,
} from "@heroicons/react/24/outline";

const navigationItems = [
  {
    name: "AI Response",
    href: "/ai-response",
    icon: SparklesIcon,
    current: true,
  },
  {
    name: "Template Library",
    href: "/template-library",
    icon: DocumentTextIcon,
    current: false,
  },
  { name: "Applicants", href: "/applicants", icon: UsersIcon, current: false },
];
const externalLinks = [
  {
    id: 1,
    name: "Request Feature",
    href: "https://feedback-hero.canny.io/feature-requests",
    initial: "RF",
    current: false,
  },
  {
    id: 2,
    name: "Book A Call",
    href: "https://calendly.com/feedbackhero/discussion",
    initial: "BC",
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const [navigation, setNavigation] = useState(navigationItems);
  const location = useLocation();
  const { logout } = useAuth();
  const handleNavigation = (href) => {
    if (sidebarOpen) setSidebarOpen(false);
    navigate(href);
    // set current navigation
    let temp = navigation.map((item) => {
      item.current = item.href === href;
      return item;
    });
    setNavigation(temp);
  };

  useEffect(() => {
    let currentPath = window.location.pathname;
    // If path contains 2 /, then take the first part
    if (currentPath.split("/").length > 2) {
      currentPath = `/${currentPath.split("/")[1]}`;
    }
    // set current navigation
    let temp = navigationItems.map((item) => {
      item.current = item.href === currentPath;
      return item;
    });
    setNavigation(temp);
  }, [location]);
  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Feedback Hero <sup className="text-teal-600">Alpha</sup>
                      </h2>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <div
                                  onClick={() => {
                                    handleNavigation(item.href);
                                  }}
                                  className={classNames(
                                    item.current
                                      ? "bg-gray-50 text-teal-600"
                                      : "text-gray-700 hover:text-teal-600 hover:bg-gray-50",
                                    "group cursor-pointer flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? "text-teal-600"
                                        : "text-gray-400 group-hover:text-teal-600",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            External Links
                          </div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {externalLinks.map((team) => (
                              <li key={team.name}>
                                <a
                                  href={team.href}
                                  target="_blank"
                                  className={classNames(
                                    team.current
                                      ? "bg-gray-50 text-teal-600"
                                      : "text-gray-700 hover:text-teal-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <span
                                    className={classNames(
                                      team.current
                                        ? "text-teal-600 border-teal-600"
                                        : "text-gray-400 border-gray-200 group-hover:border-teal-600 group-hover:text-teal-600",
                                      "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                                    )}
                                  >
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
            <div className="flex h-16 shrink-0 items-center">
              <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Feedback Hero <sup className="text-teal-600">Alpha</sup>
              </h2>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <div
                          onClick={() => {
                            handleNavigation(item.href);
                          }}
                          className={classNames(
                            item.current
                              ? "bg-gray-50 text-teal-600"
                              : "text-gray-700 hover:text-teal-600 hover:bg-gray-50",
                            "group cursor-pointer flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-teal-600"
                                : "text-gray-400 group-hover:text-teal-600",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </div>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">
                    External Links
                  </div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {externalLinks.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          target="_blank"
                          className={classNames(
                            team.current
                              ? "bg-gray-50 text-teal-600"
                              : "text-gray-700 hover:text-teal-600 hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <span
                            className={classNames(
                              team.current
                                ? "text-teal-600 border-teal-600"
                                : "text-gray-400 border-gray-200 group-hover:border-teal-600 group-hover:text-teal-600",
                              "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                            )}
                          >
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  <div
                    onClick={() => {
                      logout();
                    }}
                    className="px-4 mb-2 group cursor-pointer flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  >
                    <ArrowRightEndOnRectangleIcon
                      className="text-gray-400 group-hover:text-red-600 h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />
                    Logout
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
            Dashboard
          </div>
          <div
            onClick={() => {
              logout();
            }}
            className="px-4 mb-2 mt-1 group cursor-pointer flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
          >
            <ArrowRightEndOnRectangleIcon
              className="text-gray-400 group-hover:text-red-600 h-6 w-6 shrink-0"
              aria-hidden="true"
            />
          </div>
        </div>

        <main className="py-10 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">{children}</div>
        </main>
      </div>
    </>
  );
}
