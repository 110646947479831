const RightSection = ({ Loading, Feedback }) => {
  return (
    <div>
      <p className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl border-b pb-2 mb-2">
        Model's Output
      </p>
      {Loading ? (
        <div
          role="status"
          className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center mt-4"
        >
          <div className="w-full">
            <div className="h-2 bg-gray-200 rounded-full max-w-[480px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full max-w-[440px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full max-w-[460px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full max-w-[360px]"></div>
          </div>
        </div>
      ) : (
        <div>
          {Feedback ? (
            <p className="text-gray-900">{Feedback}</p>
          ) : (
            <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span className="mt-2 block text-sm font-medium leading-6 text-gray-900">
                Fill In The Feedback Form To Get Started
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default RightSection;
