import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
const ViewModal = ({ open, setOpen, template, updateTemplate }) => {
  const cancelButtonRef = useRef(null);
  const [contentOriginal, setContentOrignal] = useState(template?.value);
  const [content, setContent] = useState(template?.value);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (content !== contentOriginal) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [content]);
  useEffect(() => {
    if (!open) {
      if (content !== contentOriginal) {
        if (
          !window.confirm(
            "Are you sure you want to close? Your changes will be lost."
          )
        ) {
          setOpen(true);
        } else {
          setContent(contentOriginal);
        }
      }
    }
  }, [open]);
  const saveChanges = () => {
    setLoading(true);
    setSaveDisabled(true);
    setContentOrignal(content);
    updateTemplate(template._id, content);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {template?.title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <textarea
                        rows={10}
                        name="feedback"
                        id="feedback"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                        defaultValue={template?.value}
                        onChange={(e) => setContent(e.target.value)}
                        placeholder="Enter your feedback here..."
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 text-right">
                  {template?.type !== "default" && (
                    <button
                      type="button"
                      disabled={saveDisabled}
                      onClick={() => {
                        saveChanges();
                      }}
                      className="rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    >
                      {loading ? "Saving..." : "Save Changes"}
                    </button>
                  )}
                  <button
                    type="button"
                    className="mt-3 ml-5 inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default ViewModal;
