import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyBgouFojAWQgdAIqte94nEOejKfDvg3b9M",
  authDomain: "feedback-hero-28b90.firebaseapp.com",
  projectId: "feedback-hero-28b90",
  storageBucket: "feedback-hero-28b90.appspot.com",
  messagingSenderId: "190879330477",
  appId: "1:190879330477:web:baa044472d664618451624",
  measurementId: "G-MT0375JNCM",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
