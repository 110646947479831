import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import RegisterPage from "./pages/Register";
import ForgetPassword from "./pages/forgetPassword";
import AIResponse from "./pages/aiResponse";
import TemplateResponse from "./pages/templateResponse";
import Applicants from "./pages/applicants";
import Layout from "./components/layout";
import { ProtectedRoute } from "./ProtectedRoute";
import { PublicRoute } from "./PublicRoute";
import { AuthProvider } from "./hooks/useAuth";
function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/register"
          element={
            <PublicRoute>
              <RegisterPage />
            </PublicRoute>
          }
        />
        <Route
          path="/forget-password"
          element={
            <PublicRoute>
              <ForgetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/ai-response"
          element={
            <ProtectedRoute>
              <Layout>
                <AIResponse />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/template-library"
          element={
            <ProtectedRoute>
              <Layout>
                <TemplateResponse />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/applicants"
          element={
            <ProtectedRoute>
              <Layout>
                <Applicants />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
